export default {
  name: 'arrObjOperation',
  methods: {    
    arrayEquals(a, b) {
      return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
    },
    cloneArray(sourceArr) {
      let destArr = []
      let destObj = {}
      sourceArr.forEach(sourceObj => {
        Object.assign(destObj, sourceObj)
        destArr.push(destObj)
        destObj = {}
      })
      return destArr 
    },
    cloneObject(obj) {
      if (null == obj || "object" != typeof obj) return obj;
      var copy = obj.constructor();
      for (var attr in obj) {
          if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
      }
      return copy
    },
    arrInsertItem(arrOld, index) {
      // const arrOld = [1, 2, 3, 4, 5]
      const insert = (arr, index, newItem) => [
        // part of the array before the specified index
        ...arr.slice(0, index),
        // inserted item
        newItem,
        // part of the array after the specified index
        ...arr.slice(index)
      ]

      let tmpArrNew = {}

      switch (true) {
        case this.tabGoalType || this.tabTaskType || this.tabBlogStatus:
          tmpArrNew.order = index + 1
          tmpArrNew.name = '(new item)'
          tmpArrNew.default = false
          break
        case this.tabStatus:
          tmpArrNew.order = index + 1
          tmpArrNew.name = '(new item)'
          tmpArrNew.default = false
          tmpArrNew.color = 'secondary'
      }

      const arrNew = insert(arrOld, index, tmpArrNew)

      // Re-ordering
      let aItem = 0
      arrNew.forEach(a => {
        aItem += 1
        a.order = aItem
      })

      return arrNew
      // console.log(result)
      // [1, 10, 2, 3, 4, 5]
    },
    isObjInArr(array, object, action) {
      let isFound = false
      let findObj = {}
      array.forEach(obj => {
        if (obj.id === object.id) {
          isFound = true
          findObj = obj
        }
      })
      switch (action) {
        case 'boo':
          return isFound
        case 'object':
          return findObj
      }
    },
    // assume object is found in array
    updateObjToArrById(array, object) {
      let objectIndex = array.findIndex(obj => 
        obj.id === object.id)
      array[objectIndex] = object
      return array
    },
    isIdFoundInArray(array, id) {
      let index = array.findIndex(obj =>
        obj.id === id
      )
      if ( index > -1 ) {
        return true
      } else {
        return false
      }
    },
    addUpdateObjToArrById(array, object) {
      if (!this.isObjInArr(array, object, 'boo')) {
        array.push(object)
      } else {
        let objectIndex = array.findIndex(obj => 
          obj.id=== object.id)
        array[objectIndex] = object
      }
      return array
    },
    // Used by 'TodoCustomField.vue
    stringToArray(aString) {
      let strArray = []
      if (aString !== '' && aString !== null) {
        strArray = aString.split(', ')
      } else {
        strArray = ''
      }
      return strArray
    },
    arrayToString(aArray) {
      return aArray.join(', ')
    },
    stringToBoo(aString) {
      if (aString=='true' || aString=='True') {
        return true
      } else {
        return false
      }
    },
    booToString(aBoo) {
      if (aBoo) return 'true'
      else return 'false'
    },
    numToString(aNum) {
      if (aNum.match(/\.\d{2}$/)) {
        aNum = aNum.replace(',', '')
      }
      return aNum
      // return aNum.replace(/,(?=.*\.\d+)/g, '');
    },
    dedupArr(arr1, arr2) {
      arr1 = arr1.sort((a, b) => a.createdAt < b.createdAt ? -1 : 1)
      arr2 = arr2.sort((a, b) => a.createdAt < b.createdAt ? -1 : 1)

      arr2.forEach(arrItem2 => {
        if ( arr1.map(e => e.id).indexOf(arrItem2.id) == -1 ) {
          arr1.push(arrItem2)
        } else {
          // console.log('SAME')
        }
      })
      return arr1
    }
  }
}